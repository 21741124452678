import { Button, IconButton } from "@chakra-ui/button";
import { AddIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Spacer, Stack } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/react";
import * as React from "react";
import { AutoResizeTextarea } from "./AutoResizeTextarea";

export class LotteryInput {
  id = `${Date.now()}`;
  input = "";
}

type InputsProps = {
  onInputsChange: (inputs: LotteryInput[]) => void;
};

export const Inputs = ({ onInputsChange }: InputsProps) => {
  const [inputs, setInputs] = React.useState<Array<LotteryInput>>([]);
  const [selectedInputs, setSelectedInputs] = React.useState<
    Array<LotteryInput>
  >([]);

  return (
    <Box h="100vh">
      <Stack spacing={3} padding={3}>
        <Flex>
          <Heading as="h2">输入</Heading>
          <Spacer />
          <IconButton
            aria-label="add"
            icon={<AddIcon />}
            onClick={() => {
              const newInput = new LotteryInput();
              setInputs(inputs.concat(newInput));
              const newSelectedInputs = selectedInputs.concat(newInput);
              setSelectedInputs(newSelectedInputs);
              onInputsChange(newSelectedInputs);
            }}
          ></IconButton>
        </Flex>
        {inputs.map((input, index) => (
          <HStack key={input.id}>
            <AutoResizeTextarea
              placeholder="空格分割数字"
              isDisabled={!selectedInputs.includes(input)}
              value={input.input}
              onChange={(e) => {
                const newInputs = [...inputs];
                newInputs[index].input = e.target.value;
                setInputs(newInputs);

                const newSelectedInputs = [...selectedInputs];
                newSelectedInputs[newSelectedInputs.indexOf(input)].input =
                  e.target.value;
                setSelectedInputs(newSelectedInputs);
                onInputsChange(newSelectedInputs);
              }}
            ></AutoResizeTextarea>
            <Button
              size="sm"
              onClick={() => {
                let newSelectedInputs;
                if (selectedInputs.includes(input)) {
                  newSelectedInputs = selectedInputs.filter(
                    (item) => item !== input
                  );
                } else {
                  newSelectedInputs = selectedInputs.concat(input);
                }
                setSelectedInputs(newSelectedInputs);
                onInputsChange(newSelectedInputs);
              }}
            >
              {selectedInputs.includes(input) ? "不选中" : "选中"}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setInputs(inputs.filter((i) => i !== input));
                const newSelectedInputs = selectedInputs.filter(
                  (i) => i !== input
                );
                setSelectedInputs(newSelectedInputs);
                onInputsChange(newSelectedInputs);
              }}
            >
              移除
            </Button>
          </HStack>
        ))}
      </Stack>
    </Box>
  );
};
