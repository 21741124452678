import { Box, Heading, Stack, Wrap } from "@chakra-ui/layout";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import * as React from "react";

type OptionsProps = {
  onChangeType: (type: number) => void;
  onChangeFixedNumbers: (fixedNumbers: string) => void;
  onChangeReserveNumbers: (reserveNumbers: string) => void;
  onChangeSerialNumber: (serialNumber: number) => void;
};

export const Options = ({
  onChangeType,
  onChangeFixedNumbers,
  onChangeReserveNumbers,
  onChangeSerialNumber,
}: OptionsProps) => {
  const [serialNumber, setSerialNumber] = React.useState(1);
  return (
    <Box h="100vh">
      <Stack spacing={3} padding={3}>
        <Heading as="h2">配置</Heading>
        <FormControl id="options">
          <FormLabel>彩票类型</FormLabel>
          <RadioGroup
            defaultValue={"1"}
            onChange={(value) => onChangeType(parseInt(value))}
          >
            <HStack>
              <Radio value="1">大乐透</Radio>
              <Radio value="2">双色球</Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>固定选号</FormLabel>
          <Input
            placeholder="空格分割数字"
            onChange={(e) => onChangeFixedNumbers(e.target.value)}
          />
          <FormHelperText>固定选号表示结果中会固定出现的号</FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>保留选号</FormLabel>
          <Input
            placeholder="空格分割数字"
            onChange={(e) => onChangeReserveNumbers(e.target.value)}
          />
          <FormHelperText>保留选号表示从输入中移除的号</FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>连号规则</FormLabel>
          <Wrap>
            <Checkbox
              isChecked={(serialNumber & 1) === 1}
              onChange={(e) => {
                let temp = serialNumber ^ 1;
                if (temp === 0) {
                  temp = 1;
                }
                if (temp === 0b100) {
                  temp = 0b110;
                }
                setSerialNumber(temp);
                onChangeSerialNumber(temp);
              }}
            >
              一连
            </Checkbox>
            <Checkbox
              isChecked={(serialNumber & 0b10) === 0b10}
              onChange={(e) => {
                let temp = serialNumber ^ 0b10;
                if (temp === 0) {
                  temp = 1;
                }
                if (temp === 0b100) {
                  temp = 0b110;
                }
                setSerialNumber(temp);
                onChangeSerialNumber(temp);
              }}
            >
              二连
            </Checkbox>
            <Checkbox
              isChecked={(serialNumber & 0b100) === 0b100}
              onChange={(e) => {
                let temp = serialNumber ^ 0b100;
                if (temp === 0) {
                  temp = 1;
                }
                if (temp === 0b100) {
                  temp = 0b110;
                }
                setSerialNumber(temp);
                onChangeSerialNumber(temp);
              }}
            >
              二连以上
            </Checkbox>
          </Wrap>
        </FormControl>
      </Stack>
    </Box>
  );
};
