import * as React from "react";
import { ChakraProvider, Grid, theme } from "@chakra-ui/react";
import { Inputs } from "./Inputs";
import { Options } from "./Options";
import { Results } from "./Results";

export const App = () => {
  const [inputs, setInputs] = React.useState<Array<string>>([]);
  const [options, setOptions] = React.useState<LotteryOptions>(
    new LotteryOptions()
  );
  const [outputs, setOutputs] = React.useState<Array<Array<number>>>([]);

  return (
    <ChakraProvider theme={theme}>
      <Grid templateColumns="repeat(3, 1fr)" gap={1}>
        <Inputs
          onInputsChange={(inputs) => {
            setInputs(inputs.map((input) => input.input));
          }}
        />
        <Options
          onChangeType={(type: number) => {
            const newOptions = { ...options, type };
            setOptions(newOptions);
          }}
          onChangeFixedNumbers={(fixedNumbers: string) => {
            const newOptions = { ...options, fixedNumbers };
            setOptions(newOptions);
          }}
          onChangeReserveNumbers={(reserveNumbers: string) => {
            const newOptions = { ...options, reserveNumbers };
            setOptions(newOptions);
          }}
          onChangeSerialNumber={(serialNumber: number) => {
            const newOptions = { ...options, serialNumber };
            setOptions(newOptions);
          }}
        />
        <Results
          onClick={() => {
            const outputs = LotteryGenerator.generate(inputs, options, 10);
            setOutputs(outputs);
          }}
          numbers={outputs}
        />
      </Grid>
    </ChakraProvider>
  );
};

class LotteryOptions {
  type = 1;
  fixedNumbers = "";
  reserveNumbers = "";
  serialNumber = 1;
}

class LotteryGenerator {
  static daletouRedNumbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
  ];
  static shuangseqiuRedNumbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
  ];
  static generate(
    inputs: string[],
    options: LotteryOptions,
    length: number = 5
  ): number[][] {
    // 拿到所有输入
    let allInputsNumbers = new Set<number>();
    inputs.forEach((input) => {
      input
        .split(" ")
        .map((str) => parseInt(str))
        .filter((number) => !isNaN(number))
        .forEach((number) => allInputsNumbers.add(number));
    });

    // 去掉保留选号
    let reserveNumbers = new Set<number>();
    options.reserveNumbers
      .split(" ")
      .map((str) => parseInt(str))
      .filter((number) => !isNaN(number))
      .forEach((number) => reserveNumbers.add(number));
    reserveNumbers.forEach((number) => allInputsNumbers.delete(number));

    // 得到剩余号码
    let allNumbers =
      options.type === 1 ? this.daletouRedNumbers : this.shuangseqiuRedNumbers;
    let remainNumbers: number[] = [];
    allNumbers.forEach((number) => {
      if (!allInputsNumbers.has(number)) {
        remainNumbers.push(number);
      }
    });

    // 去掉固定选号
    let fixedNumbers = new Set<number>();
    options.fixedNumbers
      .split(" ")
      .map((str) => parseInt(str))
      .filter((number) => !isNaN(number))
      .forEach((number) => fixedNumbers.add(number));
    remainNumbers = remainNumbers.filter((number) => !fixedNumbers.has(number));

    // 选号
    let numberCount = options.type === 1 ? 5 : 6;
    let outputs: number[][] = [];
    while (outputs.length < length) {
      const shuffled = remainNumbers.sort(() => Math.random() - 0.5);
      const fixed = Array.from(fixedNumbers);
      const selected = [
        ...fixed,
        ...shuffled.slice(0, numberCount - fixed.length),
      ];
      const sorted = selected.sort((a, b) => a - b);
      if (!outputs.includes(sorted)) {
        // 一连
        if ((options.serialNumber & 1) !== 1) {
          if (!this.checkSerial(sorted)) {
            continue;
          }
        }

        // 二连
        if ((options.serialNumber & 0b10) !== 0b10) {
          if (this.checkTwoSerial(sorted) > 0) {
            continue;
          }
        }

        // 二连以上
        if ((options.serialNumber & 0b100) !== 0b100) {
          if (this.checkTwoSerial(sorted) > 1) {
            continue;
          }
        }

        outputs.push(sorted);
      }
    }

    outputs.unshift(remainNumbers.sort((a, b) => a - b));

    return outputs;
  }

  static checkSerial(numbers: number[]): boolean {
    let hasSerial = false;
    for (let index = 1; index < numbers.length; index++) {
      const lastElement = numbers[index - 1];
      const element = numbers[index];
      if (element - lastElement === 1) {
        hasSerial = true;
        break;
      }
    }
    return hasSerial;
  }

  static checkTwoSerial(numbers: number[]): number {
    let count = 0;
    for (let index = 1; index < numbers.length; index++) {
      const lastElement = numbers[index - 1];
      const element = numbers[index];
      if (element - lastElement === 1) {
        count += 1;
      }
    }
    return count;
  }
}
