import { Box, Flex, Heading, Spacer, Stack } from "@chakra-ui/layout";
import { Button, Text } from "@chakra-ui/react";
import * as React from "react";

type ResultsProps = {
  numbers: number[][];
  onClick: () => void;
};

export const Results = ({ numbers, onClick }: ResultsProps) => {
  return (
    <Box h="100vh">
      <Stack spacing={3} padding={3}>
        <Flex>
          <Heading as="h2">结果</Heading>
          <Spacer />
          <Button onClick={onClick}>生成</Button>
        </Flex>
        {numbers.map((nums, index) => {
          return <Text key={index}>{nums.join(" ")}</Text>;
        })}
      </Stack>
    </Box>
  );
};
